import React, { Component } from 'react';
import './City.css';
import CityDetails from '../CityDetails/CityDetails';
import IconButton from '../IconButton/IconButton';

class City extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
          details: false,
          loading: true
      }
    }

    componentDidMount() {
        fetch('https://api.openweathermap.org/data/2.5/weather?id=' + this.props.cityId + '&units=metric&appid=' + process.env.REACT_APP_API_KEY)
        .then((res) => res.json())
        .then(json => {
            this.setState({
                ...json,
                loading: false
            })
        })
    }

    toggleDetails = () => {
        this.setState({
            details: !this.state.details
        })
    }

    getWeatherImage = () => {
        let imageSrc;
        const time = (new Date(this.state.dt * 1000)).getHours() + this.state.timezone / 3600 - 1;

        switch (this.state.weather[0].main) {
            case 'Clear':
                if (time > 20 || time < 6) {
                    imageSrc = "night_";
                }
                else {
                    imageSrc = "day_";
                }

                imageSrc += "clear";
                break; 
            case 'Clouds':
                if (this.state.weather[0].id === 801) {
                    if (time > 20 || time < 6) {
                        imageSrc = "night_";
                    }
                    else {
                        imageSrc = "day_";
                    }

                    imageSrc += "few_clouds";
                }
                else {
                    imageSrc = "clouds"
                }
                break; 
            case 'Rain':
            case 'Drizzle':
                imageSrc = "rain"
                break; 
            case 'Thunderstorm':
                imageSrc = "storm"
                break; 
            case 'Snow':
                imageSrc = "snow"
                break; 
            default:
                if (time > 20 || time < 6) {
                    imageSrc = "night_";
                }
                else {
                    imageSrc = "day_";
                }

                imageSrc += "clear";
                break; 
        }

        imageSrc += "_dark.png";

        return imageSrc;
    }

    render() {

        let classes = "";

        if (!this.state.loading) {
            if (this.state.main.temp <= 10) {
                classes = "cold";
            }
            else if (this.state.main.temp >= 17) {
                classes = "warm"
            }
        }

        return (
            this.state.loading 
            ? 
            <div className='city-entry-placeholder'>
            </div> 
            :
            <div className={'city-entry ' + classes + (this.state.details ? ' details-on' : '')}>
                <div className='city-entry-summary' onClick={this.toggleDetails}>
                    <div className='left'>
                        <span>{Math.round(this.state.main.temp)}°</span>
                        <span><i className="fas fa-map-marker-alt"></i> {this.state.name}, {this.state.sys.country}</span>
                    </div>

                    <div className='center'>
                        <div>
                            <span>Min</span>
                            <span>{Math.round(this.state.main.temp_min)}°</span>
                        </div>
                        <div>
                            <span>Max</span>
                            <span>{Math.round(this.state.main.temp_max)}°</span>
                        </div>
                    </div>

                    <div className='right'>
                        <img src={"assets/img/" + this.getWeatherImage()} alt={this.state.weather[0].main} width="100px" />
                        <span>{this.state.weather[0].main}</span>
                    </div>

                    <div className="remove-button">
                        <IconButton icon="fas fa-times" onClick={() => this.props.removeCity(this.props.cityId)} />
                    </div>
                </div>
                
                <div className='city-entry-details'>
                    { this.state.details && <CityDetails cityId={this.props.cityId} darkTheme={this.props.darkTheme}/> }
                </div>
            </div>
        )
    }
}

export default City