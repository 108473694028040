import React, { Component } from 'react';
import Auth from '../Auth/Auth';
import Homepage from '../Homepage/Homepage';

class SupWeather extends Component {

    constructor(props) {
        super(props);

        this.state = {
            logged: false,
            user: null,
            loading: true,
            darkTheme: false
        }
    }

    componentDidMount() {
        if (sessionStorage.getItem('token')) {
            fetch((process.env.REACT_APP_API_HOSTNAME || 'http://localhost:4000') + '/api/auth/verify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: encodeURI('token') + "=" + encodeURI(sessionStorage.getItem('token'))
            })
            .then((res) => res.json())
            .then(json => {
                if (json.status === 200) {
                    fetch((process.env.REACT_APP_API_HOSTNAME || 'http://localhost:4000') + '/api/darkmode/get', {
                    method: 'GET',
                    headers: {
                        'Authorization': 'JWT ' + sessionStorage.getItem('token')
                    }
                    })
                    .then((res) => res.json())
                    .then(json => {
                        this.setState({
                            darkTheme: json.darkMode
                        })
                    })
                } 

                this.setState({
                    logged: json.validity,
                    user: json.user,
                    loading: false
                })
            })
        }
        else {
            this.setState({
                loading: false
            })
        }
    }

    setLogged = () => {
        this.setState({
            logged: true
        })
    }

    logout = () => {
        this.setState({
            logged: false
        })
    }

    toggleDarkTheme = () => {

        fetch((process.env.REACT_APP_API_HOSTNAME || 'http://localhost:4000') + '/api/darkmode/toggle', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'JWT ' + sessionStorage.getItem('token')
                  }
            })
            .then((res) => res.json())
            .then(json => {
                this.setState({
                    darkTheme: !this.state.darkTheme
                })
            })        
    }

    render() {
        return (
            <div>
                { this.state.loading ? <p>Loading</p> : 
                this.state.logged ? <Homepage logout={this.logout} toggleDarkTheme={this.toggleDarkTheme} darkTheme={this.state.darkTheme} /> : <Auth setLogged={this.setLogged}/> 
                }
            </div>
        )
    }
}

export default SupWeather