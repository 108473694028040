import React, { Component } from 'react';
import './Homepage.css';
import Navbar from '../Navbar/Navbar';
import SearchBox from '../SearchBox/SearchBox';
import City from '../City/City';

class Homepage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cities: []
        }
    }

    componentDidMount() {
        fetch((process.env.REACT_APP_API_HOSTNAME || 'http://localhost:4000') + '/api/city/get', {
            method: 'GET',
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
              },
        })
        .then((res) => res.json())
        .then(json => {
            this.setState({
                cities: json.cities
            })
        })
    }

    addCity = (id) => {
        let cities = this.state.cities.slice();
        cities.push(id);

        this.setState({
            cities: cities
        })
    }

    removeCity = (id) => {
        fetch((process.env.REACT_APP_API_HOSTNAME || 'http://localhost:4000') + '/api/city/remove', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
              },
            body: encodeURI('cityId') + "=" + encodeURI(id)
        })
        .then((res) => res.json())
        .then(json => {
            let cities = this.state.cities.slice();
            cities.splice(cities.indexOf(id), 1);

            this.setState({
                cities: cities
            })
        })
    }

    render() {
        return (
            <div className={"main-container" + (this.props.darkTheme ? ' dark' : '')}>
                <Navbar logout={this.props.logout} darkTheme={this.props.darkTheme} toggleDarkTheme={this.props.toggleDarkTheme} />

                <div className="content">
                    <SearchBox cities={this.state.cities} addCity={this.addCity} />
                    {
                        this.state.cities.map((city) => {
                            return <City key={city} cityId={city} removeCity={this.removeCity} darkTheme={this.props.darkTheme} />
                        })
                    }
                </div>
            </div>
        )
    }
}

export default Homepage;