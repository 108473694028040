import React, { Component } from 'react';
import './CityDetails.css';
import Day from '../Day/Day';

class CityDetails extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        days: []
      }
    }

    componentDidMount() {
        fetch('https://api.openweathermap.org/data/2.5/forecast?id=' + this.props.cityId + '&units=metric&appid=' + process.env.REACT_APP_API_KEY)
        .then((res) => res.json())
        .then(json => {
            let days = [];

            for (let i=0; i < 5; i++) {
                const date = new Date(json.list[i*8].dt*1000);
                days.push({
                    date: (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1),
                    weather: json.list[i*8].weather[0].main,
                    weather_id: json.list[i*8].weather[0].id,
                    temp_min: json.list[i*8].main.temp_min,
                    temp_max: json.list[i*8].main.temp_max
                })
            }

            this.setState({
                days: days
            })
        })
    }

    render() {
        return (
            <div className='details-container'>
                { this.state.days.map((day, i) => {
                    return <Day key={i} day={day} darkTheme={this.props.darkTheme} />
                }) }
            </div>
        )
    }
}

export default CityDetails