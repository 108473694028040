import React, { Component } from 'react';
import './App.css';
import SupWeather from './components/SupWeather/SupWeather';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            logged: false,
            user: null
        }
    }

    verifyToken = () => {
        fetch((process.env.REACT_APP_API_HOSTNAME || 'http://localhost:4000') + '/api/auth/verify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: encodeURI('token') + "=" + encodeURI(sessionStorage.getItem('token'))
        })
            .then((res) => res.json())
            .then(json => {
                if (json.validity) {
                    this.setState({
                        logged: true,
                        user: json.user
                    })
                }
            })
    }

    setLogged = () => {
        this.verifyToken();
    }

    render() {
        return (
            <SupWeather />
        )
    }
}

export default App;
