import React, { Component } from 'react';
import FormInput from '../FormInput/FormInput';
import Button from '../Button/Button';

class SignUp extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
          emailInput: '',
          passwordInput: '',
          confirmPasswordInput: ''
      }
    }

    handleEmailInputChange = (event) => {
        this.setState({ emailInput: event.target.value });
    }

    handlePasswordInputChange = (event) => {
        this.setState({ passwordInput: event.target.value });
    }

    handleConfirmPasswordInputChange = (event) => {
        this.setState({ confirmPasswordInput: event.target.value });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        fetch((process.env.REACT_APP_API_HOSTNAME || 'http://localhost:4000') + '/api/auth/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
            body: encodeURI('email') + "=" + encodeURI(this.state.emailInput) + "&" + encodeURI('password') + "=" + encodeURI(this.state.passwordInput) + "&" + encodeURI('confirmPassword') + "=" + encodeURI(this.state.confirmPasswordInput)
        })

        this.setState({
            emailInput: '',
            passwordInput: '',
            confirmPasswordInput: ''
        })
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <h1>Sign Up</h1>

                <FormInput type='email' label='Email' id='signinEmail' value={this.state.emailInput} placeholder='example@example.com' handleInputChange={this.handleEmailInputChange} />

                <FormInput type='password' label='Password' id='signinPassword' value={this.state.passwordInput} placeholder='Enter your password' handleInputChange={this.handlePasswordInputChange} />

                <div className="submit-button">
                    <Button text='Sign Up' submit='true' fancy='true' />
                </div>

                <button className="toggle-auth-link" onClick={this.props.signin}>Already registered ? Sign in</button>
            </form>
        )
    }
}

export default SignUp