import React, { Component } from 'react';
import './SearchBox.css';
import FormInput from '../FormInput/FormInput';
import IconButton from '../IconButton/IconButton';
import Button from '../Button/Button';

class SearchBox extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
          searchInput: '',
          searchedCities: undefined,
          searchError: false,
          searchErrorMsg: undefined
      }
    }

    handleSearchInputChange = (event) => {
        this.setState({ searchInput: event.target.value });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        fetch('https://api.openweathermap.org/data/2.5/find?q=' + this.state.searchInput + '&appid=' + process.env.REACT_APP_API_KEY, {
            method: 'GET'
        })
        .then((res) => res.json())
        .then(json => {
            if (json.cod === "200") {
                if (json.count === 0) {
                    this.setState({
                        searchedCities: undefined,
                        searchError: true,
                        searchErrorMsg: 'City not found'
                    })
                }
                else {
                    this.setState({
                        searchedCities: json.list,
                        searchError: false,
                        searchErrorMsg: undefined
                    })
                }
            }
            else {
                let errorMsg = 'Error: Try again later';
                if (this.state.searchInput === '') {
                    errorMsg = "You must specify a city";
                }

                this.setState({
                    searchedCities: undefined,
                    searchError: true,
                    searchErrorMsg: errorMsg
                })
            }
        })

        this.setState({
            searchInput: ''
        })
    }

    addCity = (id) => {
        fetch((process.env.REACT_APP_API_HOSTNAME || 'http://localhost:4000') + '/api/city/add', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
              },
            body: encodeURI('cityId') + "=" + encodeURI(id)
        })
        .then((res) => res.json())
        .then(json => {
            this.props.addCity(id.toString());

            this.setState({
                searchedCities: undefined,
                searchError: false,
                searchErrorMsg: undefined
            })
        })
    }

    render() {
        return (
            <div className='search-box'>
                <form onSubmit={this.handleSubmit}>
                    <FormInput type='text' id='city' value={this.state.searchInput} handleInputChange={this.handleSearchInputChange} placeholder='Search a city' />
                    <IconButton icon="fas fa-search" submit='true' />
                </form>

                { (this.state.searchedCities !== undefined || this.state.searchError) && 
                <div className="search-results">
                    { (this.state.searchError) ? <div className='warning'><p>{ this.state.searchErrorMsg }</p></div> : 
                    this.state.searchedCities.map((city) => {
                        return (
                        <div key={city.id} className="search-entry">
                            <p><span>{city.name}</span>, {city.sys.country}</p>
                            <Button size='sm' text={ this.props.cities.includes(city.id.toString()) ? 'Already Added' : 'Add' } onClick={() => this.addCity(city.id)} disabled={ this.props.cities.includes(city.id.toString()) && true } />
                        </div>)
                    })
                    }
                </div>
                }
            </div>
        )
    }
}

export default SearchBox