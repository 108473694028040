import React from 'react';
import './IconButton.css';

function IconButton({ icon, submit, onClick }) {

    return (
        <button className="icon-button" type={submit ? 'submit' : 'button'} onClick={onClick}><i className={icon}></i></button>
    )
}

export default IconButton