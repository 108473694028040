import React, { Component } from 'react';
import './Navbar.css';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';

class Navbar extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    logout = () => {
        sessionStorage.removeItem('token');
        this.props.logout();
    }

    render() {
        return (
            <div className='navbar'>
                <div className='logo'></div>

                <ul className="navbar-links">
                </ul>
                
                <Checkbox id='toggleTheme' label='Dark Mode' checked={this.props.darkTheme} onChange={this.props.toggleDarkTheme} />

                <div className="logout-button" id="logout">
                    <Button text='Logout' size='sm' fancy='true' onClick={this.logout} />
                </div>
            </div>
        )
    }
}

export default Navbar;