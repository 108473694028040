import React from 'react';
import './Day.css';

function Day(props) {

    let { date, weather, weather_id, temp_min, temp_max } = props.day;

    let imageSrc;
    switch (weather) {
        case 'Clear':
            imageSrc = "day_clear";
            break; 
        case 'Clouds':
            if (weather_id === 801) {
                imageSrc = "day_few_clouds";
            }
            else {
                imageSrc = "clouds"
            }
            break; 
        case 'Rain':
        case 'Drizzle':
            imageSrc = "rain"
            break; 
        case 'Thunderstorm':
            imageSrc = "storm"
            break; 
        case 'Snow':
            imageSrc = "snow"
            break; 
        default:
            imageSrc = "day_clear";
            break; 
    }

    if (props.darkTheme) {
        imageSrc += "_dark.png";
    }
    else {
        imageSrc += "_light.png";
    }

    return (
        <div className='day-details'>
            <span>{ date }</span>
            <img src={"assets/img/" + imageSrc} alt={ weather }></img>
            <span>{ weather }</span>
            <div className='temp'>
                <span>{ Math.round(temp_min) }°</span>
                <span>{ Math.round(temp_max) }°</span>
                <span>Min</span>
                <span>Max</span>
            </div>
        </div>
    )
}

export default Day