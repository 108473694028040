import React from 'react';
import './Checkbox.css';

function Checkbox({ id, label, checked, onChange }) {

    return (
        <div className="checkbox-input">
            <input type="checkbox" id={id} name={id} onChange={onChange} checked={checked}/>
            <label htmlFor={id}>{label}</label>
        </div>
    )
}

export default Checkbox