import React from 'react';
import './FormInput.css';

function FormInput({ type, label, id, value, placeholder, handleInputChange }) {

    return (
        <div className="input text-input">
            <label htmlFor={id}>{label}</label>
            <input type={type} id={id} name={id} value={value} onChange={handleInputChange} placeholder={placeholder} />
        </div>
    )
}

export default FormInput