import React from 'react';
import './Button.css';

function Button({ text, submit, fancy, size, disabled, onClick }) {

    let classes = 'button';
    if (size === 'sm') {
        classes += ' button-sm';
    }
    if (fancy) {
        classes += ' fancy-button';
    }

    return (
        <button className={classes} type={submit ? 'submit' : 'button'} onClick={onClick} disabled={disabled}>{text}</button>
    )
}

export default Button