import React, { Component } from 'react';
import './Auth.css';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';

import logo from "../../assets/img/logo.png";

class Auth extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        signin: true,
        signup: false
      }
    }
    
    toggleSignupSignin = (event) => {
        event.preventDefault();

        this.setState({
            signin: !this.state.signin,
            signup: !this.state.signup
        })
    }

    render() {
        return (
            <div>
                <div className="duotone"></div>

                <div className={"container" + (this.state.signup === true ? " align-right" : "")}>
                    <img className="logo" src={logo} alt="SupWeather" />

                    {this.state.signin === true && <SignIn signup={this.toggleSignupSignin} setLogged={this.props.setLogged}/>}
                    {this.state.signup === true && <SignUp signin={this.toggleSignupSignin} />}
                </div>
            </div>
        )
    }
}

export default Auth